import instance from "../helpers/api.instance";

export const fetchGlobalData = async ({
  setloading,
  view,
  zona,
  numeroEmpleado,
  numeroTienda,
  cadenaComercial,
  mes,
  anio,
  meta,
  setavances,
  setSubtotal,
  setTitleFecha,
  setAvanceIdeal,
}) => {
  setloading(true);
  setavances(null);

  if (mes && anio) {
    try {
      let response = null;
      const requestBody = { cadenaComercial, mes, anio, meta };

      switch (view) {
        case 1:
          response = await instance.post("/avances/globalZonasTabla", requestBody);
          break;
        case 2:
          response = await instance.post("/avances/globalPorZonasTabla", { ...requestBody, zona });
          break;
        case 3:
          response = await instance.post("/avances/globalPorTiendasTabla", { ...requestBody, numeroTienda });
          break;
        case 4:
          response = await instance.post("/avances/globalPorEmpleado", { ...requestBody, numeroTienda, numeroEmpleado });
          break;
        default:
          break;
      }

      if (response?.status === 1) {
        setavances(response.data.avances);
        setSubtotal(response.data.subTotal);
        setTitleFecha(response.data.fechaActualizacion);
        setAvanceIdeal(response.data.porcentajeIdeal);
      } else {
        setavances(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setloading(false);
    }
  }
};
