import React, { useEffect, useMemo, useState } from "react";
import { fetchGlobalData } from "../../../hooks/useFetchGlobalDataTable";
import DownloadButton from '../../../components/general/DownloadButton';
import { ModalHistorico } from './ModalHistorico';
import { ModalHistoricoEmpleado } from './ModalHistoricoEmpleado';
import ReporteTiendasAll from "../../../components/reportes/ReporteTiendasAll";
import ReporteEmpleadosAll from "../../../components/reportes/ReporteEmpleadosAll";
import ZonasTable from '../Tables/ZonasTable';
import ZonasTableIndividual from '../Tables/ZonasTableIndividual';
import TiendaTable from '../Tables/TiendaTable';
import EmpleadoTable from '../Tables/EmpleadoTable';

const DataTable = (props) => {
  const {
    setloading,
    view,
    zona,
    numeroEmpleado,
    numeroTienda,
    usuario,
    cadenaComercial,
    handleGlobal,
    handleZona,
    handleTienda,
    setTitleFecha,
    mes,
    anio,
    meta,
    userInfo,
    //dateNow,
  } = props;

  // Estado para datos y configuración
  const [avances, setavances] = useState(null);
  const [mesActual, setMesActual] = useState(null);
  const [subTotal, setSubtotal] = useState(null);
  const [tiendaNombre, setTiendaNombre] = useState('');
  const [avanceIdeal, setAvanceIdeal] = useState(null);
  const [nombreEmpleado, setNombreEmpleado] = useState(null);

  const tienePermisoTiendasAll = userInfo?.permisos?.includes("reporte.avances.tiendas.all");
  const tienePermisoEmpleadosAll = userInfo?.permisos?.includes("reporte.avances.colaboradores.all");

  useEffect(() => {
    const mesActual = new Date().getMonth() + 1;
    setMesActual(mesActual);

    fetchGlobalData({
      view,
      cadenaComercial,
      zona,
      numeroTienda,
      numeroEmpleado,
      mes,
      anio,
      meta,
      setloading,
      setavances,
      setSubtotal,
      setTitleFecha,
      setAvanceIdeal
    });
  }, [view, cadenaComercial, mes, anio, meta]);

  const data = useMemo(() => avances, [avances]);

  if (data === 0) return <Warning />;
  if (!data) return null;

  const renderTable = () => {
    const commonProps = {
      data,
      cadenaComercial,
      avanceIdeal,
      mes,
      mesActual,
    };

    switch (view) {
      case 1:
        return (
          <>
            <DownloadButton id="ranking-nacional" />
            <ZonasTable {...commonProps} handleGlobal={handleGlobal} />
          </>
        );
      case 2:
        return (
          <>
            <DownloadButton id="ranking-zona" buttonText="Descargar tiendas de zona" />
            {tienePermisoTiendasAll && (
              <div>
                <ReporteTiendasAll
                  cadenaComercial={cadenaComercial}
                  meta={meta}
                  mes={mes}
                  anio={anio}
                  tipo="tiendas"
                  setloading={setloading}
                />
              </div>
            )}
            <ZonasTableIndividual {...commonProps} handleZona={handleZona} setTiendaNombre={setTiendaNombre} usuario={usuario} />
          </>
        );
      case 3:
        return (
          <>
            <DownloadButton id="ranking-tienda" buttonText="Descargar asesores tienda" filenameSuffix="ranking-tienda" />
            {tienePermisoEmpleadosAll && (
              <div>
                <ReporteEmpleadosAll
                  cadenaComercial={cadenaComercial}
                  meta={meta}
                  mes={mes}
                  anio={anio}
                  tipo="empleados"
                  setloading={setloading}
                />
              </div>
            )}
            <ModalHistorico
              tienda={tiendaNombre}
              cadenaComercial={cadenaComercial}
              numeroTienda={numeroTienda}
              nombreEmpleado={nombreEmpleado}
            />
            <TiendaTable {...commonProps} handleTienda={handleTienda} setNombreEmpleado={setNombreEmpleado} />
          </>
        );
      case 4:
        return (
          <>
            <DownloadButton id="detalle-empleado" />
            <ModalHistoricoEmpleado
              numeroEmpleado={numeroEmpleado}
              tienda={tiendaNombre}
              cadenaComercial={cadenaComercial}
              numeroTienda={numeroTienda}
              nombreEmpleado={nombreEmpleado}
            />
            <EmpleadoTable {...commonProps} />
          </>
        );
      default:
        return null;
    }
  };

  return renderTable();
};

export const Warning = () => {
  return (
    <div className="text-center alert alert-warning my-1rem" role="alert">
      Aún no hay avances aquí.
    </div>
  );
};

export default DataTable;
