import { colorSemaphoreAvance, normalizePercentage, colorSemaphore } from "../../../helpers/Helpers";

const EmpleadoTable = (props) => {
  const { data, cadenaComercial, avanceIdeal, mes, mesActual } = props;

  return (
    <table id="detalle-empleado" className="table table-bordered table-hover table-sm">
        <thead>
            <tr>
            <th rowSpan='3' className="align-middle table-dark">
                Fecha
            </th>
            <th colSpan="5" scope="colgroup" className="even-title">
                Reactivaciones
            </th>
            <th colSpan="5" scope="colgroup" className="table-dark">
                Migraciones
            </th>
            {cadenaComercial === '2' &&
                <th rowSpan="1" colSpan="3" className="align-middle even-title">
                Cuentas nuevas
                </th>
            }
            {cadenaComercial === '1' &&
                <th colSpan='12' scope="colgroup" className="even-title">
                Cuentas nuevas
                </th>
            }
            <th rowSpan='3' className="align-middle table-dark">
                Cuentas canceladas <br /> en el mismo mes
            </th>
            <th rowSpan='3' className="align-middle table-dark even-title">
                %<br />cancelación
            </th>
            <th
                colSpan={cadenaComercial === '1' ? '8' : '0'}
                rowSpan={cadenaComercial === '2' ? '3' : '1'}
                scope="colgroup" className="table-dark align-middle">
                Rechazo inicial <br /> individual
            </th>
            <th colSpan="2" scope="colgroup" className="even-title">
                IPPF
            </th>
            </tr>

            <tr className="table-bordered">
            {/* Reactivaciones */}
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Meta
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Avance del día
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Acumulado
            </th>
            <th rowSpan={cadenaComercial === '1' ? '2' : '0'} scope="col" className="align-middle sticky-header even">
                Faltan
            </th>
            <th
                rowSpan={cadenaComercial === '1' ? '2' : '0'}
                scope="col"
                className="text-sm align-middle sticky-header even"
            >
                % logro
            </th>

            {/* Migraciones */}
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Meta
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Avance del día
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Acumulado
            </th>
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                Faltan
            </th>
            <th
                scope="col"
                rowSpan={cadenaComercial === '1' ? '2' : '0'}
                className="text-sm align-middle sticky-header odd"
            >
                % logro
            </th>
            {cadenaComercial === '2' &&
                <>
                <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                    Total CN
                </th>
                <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                    # Básico con <br />
                    {'LC > 5000'}
                </th>
                <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                    % Básico con <br />
                    {'LC > 5000'}
                </th>
                </>
            }
            {cadenaComercial === '1' &&
                <>
                <th scope="col" colSpan='3' className="align-middle sticky-header even">
                    CN
                </th>
                <th scope="col" colSpan='3' className="align-middle sticky-header even">
                    CN CP
                </th>
                <th scope="col" colSpan='3' className="align-middle sticky-header even">
                    CN CP <br /> SF
                </th>
                <th scope="col" colSpan='3' rowSpan='1' className="align-middle sticky-header even">
                    CN<br />total
                </th>
                </>
            }
            {cadenaComercial === '1' &&
                <>
                <th
                    scope="col"
                    colSpan='2'
                    className="align-middle sticky-header even px-1rem"
                >
                    CN
                </th>
                <th
                    scope="col"
                    colSpan='2'
                    className="align-middle sticky-header even px-1rem"
                >
                    CN CP
                </th>
                <th
                    scope="col"
                    colSpan='2'
                    className="align-middle sticky-header even px-1rem"
                >
                    CN CP<br />SF
                </th>
                <th
                    scope="col"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    className="align-middle sticky-header even px-1rem"
                >
                    Cuentas totales<br />rechazadas
                </th>
                <th
                    scope="col"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    className="align-middle sticky-header even px-1rem"
                >
                    Rechazo Inicial
                </th>
                </>
            }

            {/* IPPF */}
            <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                Meta tienda
            </th>
            <th
                scope="col"
                rowSpan={cadenaComercial === '1' ? '2' : '0'}
                className="text-sm align-middle sticky-header even"
            >
                {(mes === mesActual) ? 'IPPF por asesor al mes anterior' : 'IPPF por asesor al cierre de mes'}
            </th>
            </tr>
            {cadenaComercial === '1' &&
            <tr>
                <th scope="colgroup" className="align-middle sticky-header even">
                Total
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico #
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico %
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Total
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico #
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico %
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Total
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico #
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico %
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Total
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico #
                </th>
                <th scope="colgroup" className="align-middle sticky-header even">
                Básico %
                </th>
                <th scope="col" className="align-middle sticky-header even">
                Cantidad
                </th>
                <th scope="col" className="align-middle sticky-header even">
                %
                </th>
                <th scope="col" className="align-middle sticky-header even">
                Cantidad
                </th>
                <th scope="col" className="align-middle sticky-header even">
                %
                </th>
                <th scope="col" className="align-middle sticky-header even">
                Cantidad
                </th>
                <th scope="col" className="align-middle sticky-header even">
                %
                </th>
            </tr>
            }
        </thead>

        <colgroup>
            <col span="2" />
            <col span="5" className="even" />
            <col span="5" />
            <col span="4" className="even" />
            <col span="1" />
        </colgroup>

        <tbody>
            {data.map((row, i) => {
            // Cuentas nuevas
            // Cuentas nuevas CN
            const cnTotal = parseFloat(row.cuentasNuevas?.cn.total);
            const cnBasicoNumero = parseFloat(row.cuentasNuevas?.cn.totalBasico);
            const cnBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cn.totalBasicoPorcentaje);

            // Cuentas nuevas CNCP
            const cncpTotal = parseFloat(row.cuentasNuevas?.cncp.total);
            const cncpBasicoNumero = parseFloat(row.cuentasNuevas?.cncp.totalBasico);
            const cncpBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncp.totalBasicoPorcentaje);

            // Cuentas nuevas CNCPSF
            const cncpsfTotal = parseFloat(row.cuentasNuevas?.cncpsf.total);
            const cncpsfBasicoNumero = parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
            const cncpsfBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncpsf.totalBasicoPorcentaje);

            // CN Total
            const cnTotalTotal = parseFloat(row.cuentasNuevas?.total);
            const cnTotalBasicoNumero = parseFloat(row.cuentasNuevas?.totalBasico);
            const cnTotalBasicoPorcentaje = parseFloat(row.cuentasNuevas?.totalBasicoPorcentaje);

            // Cuentas nuevas Tlc
            const cncpTotalTlc = parseFloat(row.cuentasNuevas?.tlc.total);
            const cncpNumeroTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasico);
            const cncpPorcentajeTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasicoPorcentaje);
            
            return (
                <tr key={i}>
                {/* General */}
                <td>{row.fecha}</td>

                {/* Reactivación */}
                <td className="even">{row.metaReactivacion}</td>
                <td>{row.avanceDiaReactivacion}</td>
                <td>{row.avanceReactivacion}</td>
                <td>{row.faltanReactivacion}</td>
                <td
                    className={`bg-${colorSemaphoreAvance(
                    row.porcentajeReactivacion,
                    avanceIdeal
                    )}`}
                >
                    {normalizePercentage(row.porcentajeReactivacion)}
                </td>

                {/* Migraciones */}
                <td className="odd">{row.metaMigracion}</td>
                <td>{row.avanceDiaMigracion}</td>
                <td>{row.avanceMigracion}</td>
                <td>{row.faltanMigracion}</td>
                <td
                    className={`bg-${colorSemaphoreAvance(
                    row.porcentajeMigracion,
                    avanceIdeal
                    )}`}
                >
                    {normalizePercentage(row.porcentajeMigracion)}
                </td>

                {/* Cuentas nuevas */}
                {cadenaComercial === '2' &&
                    <>
                    <td  className="align-middle sticky-header even">{cncpTotalTlc}</td>
                    <td  className="align-middle sticky-header even">{cncpNumeroTlc}</td>
                    <td style={{ backgroundColor: cncpPorcentajeTlc <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }} className="align-middle sticky-header even">{normalizePercentage(cncpPorcentajeTlc)}</td>
                    {/* <td>{row.tarjetasEntregadas}</td> */}
                    </>
                }
                {cadenaComercial === '1' &&
                    <>
                    <td className="even">{cnTotal}</td>
                    <td>{cnBasicoNumero}</td>
                    <td style={{ backgroundColor: cnBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnBasicoPorcentaje)}</td>
                    <td>{cncpTotal}</td>
                    <td>{cncpBasicoNumero}</td>
                    <td style={{ backgroundColor: cncpBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpBasicoPorcentaje)}</td>
                    <td className="even">{cncpsfTotal}</td>
                    <td className="even">{cncpsfBasicoNumero}</td>
                    <td style={{ backgroundColor: cncpsfBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpsfBasicoPorcentaje)}</td>
                    <td className="even">{cnTotalTotal}</td>
                    <td className="even">{cnTotalBasicoNumero}</td>
                    <td style={{ backgroundColor: cnTotalBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnTotalBasicoPorcentaje)}</td>
                    </>
                }
                <td>{row.canceladas}</td>
                <td>{normalizePercentage(row.porcentajeCanceladas)}</td>
                {/* Rechazo incial individual*/}
                {cadenaComercial === '1' &&
                    <>
                    <td>{row.rechazoTotalCN}</td>
                    <td>{normalizePercentage(row.rechazoInicial)}</td>
                    <td>{row.rechazoTotalCNCP}</td>
                    <td>{normalizePercentage(row.rechazoInicialCNCP)}</td>
                    <td>{row.rechazoTotalCNCPSF}</td>
                    <td>{normalizePercentage(row.rechazoInicialCNCPSF)}</td>
                    <td>{row.totalCuentasRechazo}</td>
                    </>
                }
                <td
                    className={`bg-${colorSemaphore(
                    "rechazo",
                    row.rechazoInicial,
                    cadenaComercial
                    )}`}
                >
                    {normalizePercentage(row.rechazoInicial)}
                </td>

                {/* IPPF */}
                <td>{normalizePercentage(row.metaIppfTienda)}</td>
                <td
                    // className={`bg-${colorSemaphore(
                    //   "mayorOMenor",
                    //   Number(row.ippfAsesor),
                    //   cadenaComercial,
                    //   Number(row.metaIppfTienda)
                    // )}`}
                    style={{ backgroundColor: row.ippfAsesor >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}
                >
                    {normalizePercentage(row.ippfAsesor)}
                </td>
                </tr>
            );
            })}
        </tbody>
    </table>
  );
};

export default EmpleadoTable;
