import { colorSemaphoreAvance, normalizePercentage, formatoMiles, colorSemaphore } from "../../../helpers/Helpers";

const TiendaTable = (props) => {
  const { 
        data, 
        cadenaComercial, 
        avanceIdeal, 
        mes, 
        handleTienda, 
        mesActual, 
        setNombreEmpleado 
    } = props

  let suma1 = 0, suma2 = 0, suma3 = 0, suma4 = 0, suma5 = 0,
      suma6 = 0, suma7 = 0, suma8 = 0, suma9 = 0,
      suma10 = 0, sumaCN = 0, suma15 = 0, suma20 = 0,
      sumaCNCP = 0, suma16 = 0, suma21 = 0, sumaCNCPSF = 0, suma17 = 0,
      suma22 = 0, suma18 = 0, suma23 = 0,
      suma24 = 0, sumaTotalRechazado = 0, sumaCNCPTotal = 0, sumaCNTotal = 0,
      sumaCNCPSFTotal = 0, porcentajeRetencion = 0;

  return (
    <table id="ranking-tienda" className="table table-hover table-sm">
        <thead>
            <tr>
                <th rowSpan='3' className="align-middle table-dark">
                    Rank
                </th>
                <th rowSpan='3' className="align-middle table-dark">
                    # Empleado
                </th>
                <th rowSpan='3' className="align-middle table-dark">
                    Asesor
                </th>
                <th rowSpan='3' className="align-middle table-dark">
                    Área
                </th>
                <th colSpan="4" scope="colgroup" className="even-title">
                    Reactivaciones
                </th>
                <th colSpan="4" scope="colgroup" className="table-dark">
                    Migraciones
                </th>
                {cadenaComercial === '2' &&
                    <th colSpan="6" scope="colgroup" className="even-title">
                    Cuentas nuevas
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th colSpan='13' scope="colgroup" className="even-title">
                    Cuentas nuevas
                    </th>
                }
                <th
                    scope="col"
                    className="text-sm align-middle even-title px-1rem"
                    rowSpan="0"
                    >
                    % DE RETENCIÓN
                </th>
                <th
                    colSpan={cadenaComercial === '1' ? '10' : '2'}
                    scope="colgroup" className="table-dark">
                    Rechazo inicial
                </th>
                <th colSpan="2" scope="colgroup" className="even-title">
                    IPPF
                </th>
            </tr>

            <tr className="table-bordered">
                {/* Reactivaciones */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Meta individual
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Acumulado
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Faltan
                </th>
                <th
                    scope="col"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    className="text-sm align-middle sticky-header even"
                >
                    % avance
                </th>

                {/* Migraciones */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                    Meta individual
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                    Acumulado
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                    Faltan
                </th>
                <th
                    scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'}
                    className="text-sm align-middle sticky-header odd"
                >
                    % avance
                </th>
                {cadenaComercial === '2' &&
                    <>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                        Total CN
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                        # Básico con <br />
                        {'LC > 5000'}
                    </th>
                    <th scope="col" colSpan="1" className="text-sm align-middle sticky-header even">
                        % Básico con <br />
                        {'LC > 5000'}
                    </th>
                    </>
                }
                {cadenaComercial === '1' &&
                    <>
                    <th scope="col" colSpan="3" className="align-middle sticky-header even">
                        CN
                    </th>
                    <th scope="col" colSpan="3" className="align-middle sticky-header even">
                        CN CP
                    </th>
                    <th scope="col" colSpan="3" className="align-middle sticky-header even">
                        CN CP <br />SF
                    </th>
                    <th scope="col" colSpan="3" rowSpan='1' className="align-middle sticky-header even">
                        CN<br />total
                    </th>
                    </>
                }
                {cadenaComercial === '2' &&
                    <th scope="col" rowSpan='2' className="align-middle sticky-header even">
                    Cuentas canceladas <br /> en el mismo mes
                    </th>
                }
                {cadenaComercial === '1' &&
                    <th scope="col" className="align-middle sticky-header even" rowSpan={cadenaComercial === '1' ? '2' : '0'}>
                    Cuentas totales canceladas <br /> en el mismo mes
                    </th>
                }
                {cadenaComercial === '1' &&
                    <>
                    <th
                        scope="col"
                        colSpan='2'
                        className="align-middle sticky-header even px-1rem"
                    >
                        CN
                    </th>
                    <th
                        scope="col"
                        colSpan='2'
                        className="align-middle sticky-header even px-1rem"
                    >
                        CN CP
                    </th>
                    <th
                        scope="col"
                        colSpan='2'
                        className="align-middle sticky-header even px-1rem"
                    >
                        CN CP <br /> SF
                    </th>
                    </>
                }
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    %<br />cancelación
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Cuentas totales<br />rechazadas
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Anterior
                </th>
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header even">
                    Actual
                </th>

                {/* IPPF */}
                <th scope="col" rowSpan={cadenaComercial === '1' ? '2' : '0'} className="align-middle sticky-header odd">
                    Meta tienda
                </th>
                <th
                    scope="col"
                    className="text-xs align-middle sticky-header odd"
                    rowSpan={cadenaComercial === '1' ? '2' : '0'}
                >
                    {(mes === mesActual) ? 'IPPF por asesor al mes anterior' : 'IPPF por asesor al cierre de mes'}

                </th>
            </tr>

            {cadenaComercial === '1' &&
                <tr>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Total
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico #
                    </th>
                    <th scope="colgroup" className="align-middle sticky-header even">
                    Básico %
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    %
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    %
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    Cantidad
                    </th>
                    <th scope="col" className="align-middle sticky-header even">
                    %
                    </th>
                </tr>
            }
        </thead>

        <colgroup>
            <col span="5" />
            <col span="4" className="even" />
            <col span="4" />
            <col span="5" className="even" />
            <col span="3" />
        </colgroup>

        <tbody className="table-bordered">
            {data.map((row, i) => {
                // Reactivaciones
                suma1 = suma1 + parseFloat(row.metaReactivacion);
                suma2 = suma2 + parseFloat(row.avanceReactivacion);
                suma3 = suma3 + parseFloat(row.faltanReactivacion);
                // Migraciones
                suma4 = suma4 + parseFloat(row.metaMigracion);
                suma5 = suma5 + parseFloat(row.avanceMigracion);
                suma6 = suma6 + parseFloat(row.faltanMigracion);
            
                // Cuentas nuevas
                // Cuentas nuevas CN
                const cnTotal = parseFloat(row.cuentasNuevas?.cn.total);
                const cnBasicoNumero = parseFloat(row.cuentasNuevas?.cn.totalBasico);
                const cnBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cn.totalBasicoPorcentaje);
                suma7 = suma7 + parseFloat(row.cuentasNuevas?.cn.total);
                suma15 = suma15 + parseFloat(row.cuentasNuevas?.cn.totalBasico);
                suma20 = parseFloat([(suma15 * 100) / suma7]);

                // Cuentas nuevas CNCP
                const cncpTotal = parseFloat(row.cuentasNuevas?.cncp.total);
                const cncpBasicoNumero = parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                const cncpBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncp.totalBasicoPorcentaje);
                sumaCNCP = sumaCNCP + parseFloat(row.cuentasNuevas?.cncp.total);
                suma16 = suma16 + parseFloat(row.cuentasNuevas?.cncp.totalBasico);
                suma21 = parseFloat([(suma16 * 100) / sumaCNCP]);

                // Cuentas nuevas CNCPSF
                const cncpsfTotal = parseFloat(row.cuentasNuevas?.cncpsf.total);
                const cncpsfBasicoNumero = parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                const cncpsfBasicoPorcentaje = parseFloat(row.cuentasNuevas?.cncpsf.totalBasicoPorcentaje);
                sumaCNCPSF = sumaCNCPSF + parseFloat(row.cuentasNuevas?.cncpsf.total);
                suma17 = suma17 + parseFloat(row.cuentasNuevas?.cncpsf.totalBasico);
                suma22 = parseFloat([(suma17 * 100) / sumaCNCPSF]);

                // CN Total
                const cnTotalTotal = parseFloat(row.cuentasNuevas?.total);
                const cnTotalBasicoNumero = parseFloat(row.cuentasNuevas?.totalBasico);
                const cnTotalBasicoPorcentaje = parseFloat(row.cuentasNuevas?.totalBasicoPorcentaje);
                sumaCNTotal = sumaCNTotal + parseFloat(row.cuentasNuevas?.total);
                suma18 = suma18 + parseFloat(row.cuentasNuevas?.totalBasico);
                suma23 = parseFloat([(suma18 * 100) / sumaCNTotal]);

                // Cuentas nuevas Tlc
                const cncpTotalTlc = parseFloat(row.cuentasNuevas?.tlc.total);
                const cncpNumeroTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                const cncpPorcentajeTlc = parseFloat(row.cuentasNuevas?.tlc.totalBasicoPorcentaje);
                suma9 = suma9 + parseFloat(row.cuentasNuevas?.tlc.total);
                suma10 = suma10 + parseFloat(row.cuentasNuevas?.tlc.totalBasico);
                suma24 = parseFloat([(suma10 * 100) / suma9]);

                // Cuentas canceladas
                suma8 = suma8 + parseFloat(row.canceladas);
                sumaTotalRechazado = sumaTotalRechazado + parseFloat(row.totalCuentasRechazo);
                sumaCN = sumaCN + parseFloat(row.rechazoTotalCN);
                sumaCNCPTotal = sumaCNCPTotal + parseFloat(row.rechazoTotalCNCP);
                sumaCNCPSFTotal = sumaCNCPSFTotal + parseFloat(row.rechazoTotalCNCPSF);
                porcentajeRetencion = porcentajeRetencion + parseFloat(row.promedioRetencion);
                return (
                    <tr
                    key={i}
                    className="cursor-pointer"
                    onClick={() => {
                        setNombreEmpleado(row.nombreEmpleado)
                        handleTienda(row.numeroEmpleado, row.nombreEmpleado);
                    }}
                    >
                        {/* General */}
                        <td>{row.rank}</td>
                        <td>{row.numeroEmpleado}</td>
                        <td>{row.nombreEmpleado}</td>
                        <td>{row.area}</td>

                        {/* Reactivación */}
                        <td className="even">{row.metaReactivacion}</td>
                        <td>{row.avanceReactivacion}</td>
                        <td>{row.faltanReactivacion}</td>
                        <td
                            className={`bg-${colorSemaphoreAvance(
                            row.porcentajeReactivacion,
                            avanceIdeal
                            )}`}
                        >
                            {normalizePercentage(row.porcentajeReactivacion)}
                        </td>

                        {/* Migraciones */}
                        <td className="odd">{row.metaMigracion}</td>
                        <td>{row.avanceMigracion}</td>
                        <td>{row.faltanMigracion}</td>
                        <td
                            className={`bg-${colorSemaphoreAvance(
                            row.porcentajeMigracion,
                            avanceIdeal
                            )}`}
                        >
                            {normalizePercentage(row.porcentajeMigracion)}
                        </td>
                        {cadenaComercial === '2' &&
                            <>
                            <td  className="align-middle sticky-header even">{cncpTotalTlc}</td>
                            <td  className="align-middle sticky-header even">{cncpNumeroTlc}</td>
                            <td style={{ backgroundColor: cncpPorcentajeTlc <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }} className="align-middle sticky-header even">{normalizePercentage(cncpPorcentajeTlc)}</td>
                            </>
                        }

                        {/* Cuentas nuevas CN, CNCP, CNCPSF, CNTotal */}
                        {cadenaComercial === '1' &&
                            <>
                            <td className="even">{cnTotal}</td>
                            <td>{cnBasicoNumero}</td>
                            <td style={{ backgroundColor: cnBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnBasicoPorcentaje)}</td>
                            <td>{cncpTotal}</td>
                            <td>{cncpBasicoNumero}</td>
                            <td style={{ backgroundColor: cncpBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpBasicoPorcentaje)}</td>
                            <td className="even">{cncpsfTotal}</td>
                            <td className="even">{cncpsfBasicoNumero}</td>
                            <td style={{ backgroundColor: cncpsfBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cncpsfBasicoPorcentaje)}</td>
                            <td className="even">{cnTotalTotal}</td>
                            <td className="even">{cnTotalBasicoNumero}</td>
                            <td style={{ backgroundColor: cnTotalBasicoPorcentaje <= 30 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}>{normalizePercentage(cnTotalBasicoPorcentaje)}</td>
                            </>
                        }
                        <td className="even">{row.canceladas}</td>
                        {cadenaComercial === '1' &&
                            <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                        }
                        {/* Rechazo incial */}
                        {cadenaComercial === '1' &&
                            <>
                            <td>{row.rechazoTotalCN}</td>
                            <td>{normalizePercentage(row.rechazoInicialCN)}</td>
                            <td>{row.rechazoTotalCNCP}</td>
                            <td>{normalizePercentage(row.rechazoInicialCNCP)}</td>
                            <td>{row.rechazoTotalCNCPSF}</td>
                            <td>{normalizePercentage(row.rechazoInicialCNCPSF)}</td>
                            </>

                        }
                        <td>{normalizePercentage(row.porcentajeCanceladas)}</td>
                        <td className="even">{row.totalCuentasRechazo}</td>
                        {cadenaComercial === '2' &&
                            <td className="even">{normalizePercentage(row.promedioRetencion)}</td>
                        }
                        <td>{normalizePercentage(row.rechazoAnterior)}</td>
                        <td
                            className={`bg-${colorSemaphore(
                            "rechazo",
                            row.rechazoActual,
                            cadenaComercial
                            )}`}
                        >
                            {normalizePercentage(row.rechazoActual)}
                        </td>

                        {/* IPPF */}
                        <td>{normalizePercentage(row.metaIppf)}</td>
                        <td
                            // className={`bg-${colorSemaphore(
                            //   "mayorOMenor",
                            //   Number(row.ippfAnterior),
                            //   cadenaComercial,
                            //   Number(row.metaIppf)
                            // )}`}
                            style={{ backgroundColor: row.ippfAnterior >= 80 ? 'rgb(182, 238, 166)' : 'rgb(240, 100, 120)' }}
                        >
                            {normalizePercentage(row.ippfAnterior)}
                        </td>
                    </tr>
                );
            })}
        </tbody>

        <tfoot>
            <tr className="bg-white border-0">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="fw-bold">{formatoMiles(suma2)}</td>
                <td className="fw-bold">{formatoMiles(suma3)}</td>
                <td></td>
                <td></td>
                <td className="fw-bold">{formatoMiles(suma5)}</td>
                <td className="fw-bold">{formatoMiles(suma6)}</td>
                <td></td>
                {cadenaComercial === '2' &&
                <>
                    <td className="fw-bold">{formatoMiles(suma9)}</td>
                    <td className="fw-bold">{formatoMiles(suma10)}</td>
                    <td className="fw-bold">{formatoMiles(suma24.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(suma8)}</td>
                </>
                }
                {cadenaComercial === '1' &&
                <>
                    <td className="fw-bold">{formatoMiles(suma7)}</td>
                    <td className="fw-bold">{formatoMiles(suma15)}</td>
                    <td className="fw-bold">{formatoMiles(suma20.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(sumaCNCP)}</td>
                    <td className="fw-bold">{formatoMiles(suma16)}</td>
                    <td className="fw-bold">{formatoMiles(suma21.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(sumaCNCPSF)}</td>
                    <td className="fw-bold">{formatoMiles(suma17)}</td>
                    <td className="fw-bold">{formatoMiles(suma22.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(sumaCNTotal)}</td>
                    <td className="fw-bold">{formatoMiles(suma18)}</td>
                    <td className="fw-bold">{formatoMiles(suma23.toFixed(2))}%</td>
                    <td className="fw-bold">{formatoMiles(suma8)}</td>
                </>
                }
                {cadenaComercial === '1' &&
                <td className="fw-bold"></td>
                }
                {cadenaComercial === '1' &&
                <>
                    <td className="fw-bold">{formatoMiles(sumaCN)}</td>
                    <td></td>
                    <td className="fw-bold">{formatoMiles(sumaCNCPTotal)}</td>
                    <td></td>
                    <td className="fw-bold">{formatoMiles(sumaCNCPSFTotal)}</td>
                    <td></td>
                    <td></td>
                    <td className="fw-bold">{formatoMiles(sumaTotalRechazado)}</td>
                </>
                }
            </tr>
        </tfoot>
    </table>
  );
};

export default TiendaTable;
